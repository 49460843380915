import React from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/products.css"


import img1 from '../images/jacuzzi-200cm-6-osob/0001.webp';
import img2 from '../images/jacuzzi-200cm-6-osob/0002.webp';
import img3 from '../images/jacuzzi-200cm-6-osob/0003.webp';
import img4 from '../images/jacuzzi-200cm-6-osob/0004.webp';
import img5 from '../images/jacuzzi-200cm-6-osob/0005.webp';
import img6 from '../images/jacuzzi-200cm-6-osob/0006.webp';
import img7 from '../images/jacuzzi-200cm-6-osob/0007.webp';


import raty from '../images/raty-banner.jpg'

import ImageGallery from 'react-image-gallery';
 
const images = [
  {
    original: img1,
    thumbnail: img1,
  },
  {
    original: img2,
    thumbnail: img2,
  },
  {
    original: img3,
    thumbnail: img3,
  },
  {
    original: img4,
    thumbnail: img4,
  },
  {
    original: img5,
    thumbnail: img5,
  },
  {
    original: img6,
    thumbnail: img6,
  },
  {
    original: img7,
    thumbnail: img7,
  }
];

const ProductView14 = () => {

  const price = 11999;

  return(
  <Layout>
    <SEO 
      title="Jacuzzi" 
      description = { SEO.description }
      urlName="jacuzzi-okragle-200cm"
    />
    <div className="banner">
        <div className="bannerPlate">
          <p>Jacuzzi</p>
        </div>
    </div>
    <div className="productViewPlate">

      <div className="gallery">
        <ImageGallery slideInterval="2000" items={images} />
      </div>
      <div className="productDescription">
          <div id="prodTop"></div>
          <div id="prodLeft"></div>
          <div id="prodBottom"></div>
          <div id="prodRight"></div>
          <div id="productTitle">Ogrodowe Jacuzzi 6 osobowe 200cm LED</div>
          <div id="productPrice">{price}zł
          </div>
          <div className="productOption">
              <div className="productDescriptionText">
                  <h3>Opis</h3>
                  <p>
                  Jacuzzi ogrodowe to doskonały sposób na relaks w każdej porze roku. Posiada pompę z termostatem o mocy 3KW! Dzięki swoim rozmiarom spokojnie pomieści aż 6 osób!. Jacuzzi posiada regulację temperatury oraz podświetlenie LED.<br/>
                  </p>
                  <h3>Dane techniczne:</h3>
                  <ul>
                    <li>Podświetlenie LED</li>
                    <li>Pokrywa drewniana</li>
                    <li>Jacuzzi - balia z wkładem z włókna szklanego</li>
                    <li>Funkcja AIR</li>
                    <li>Pompa z termostatem 3KW</li>
                    <li>Regulacja temperatury</li>
                    <li>12 dysz powietrznych</li>
                    <li>Schodki</li>
                  </ul>
                  <a href="https://epos.inbank.pl/pl/drewhaus">
                  <img src={raty} className="creditBanner" alt="domek-ogrodowy-raty"/>
                  </a>
                  <p><b>Jacuzzi ogrodowe możesz obejrzeć w Gnieźnie! Zajrzyj do zakładki&nbsp; 
                    <Link to="/contact" style={{ color: '#765F52' }} >
                      kontakt
                    </Link> i sprawdź gdzie nas znajdziesz!</b></p>

              </div>
          </div>
      </div>
    </div>
  </Layout>
  )}

export default ProductView14


